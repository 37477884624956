<template>
  <router-link to="/account/login"
    ><img src="../../../assets/images/ommed_logo.svg" class="logo"
  /></router-link>
  <div v-if="localize" class="account-form info-form">
    <div class="form infos fadeinleft animation-duration-300">
      <div class="card-title">
        <h5 class="my-0 title-color">Redefina sua senha</h5>
      </div>
      <div class="card-body">
        <div class="recover-text mt-5 mb-5">
          <p>
            Como você deseja receber o código <br />
            para redefinir sua senha?
          </p>
        </div>
        <div class="d-flex fd-column ai-center jc-center">
          <h5 class="color-gray__3 text-bold fs-8 mb-0">{{ data.name }}</h5>
          <a href="/account/recover/searchAccount">Não é você?</a>
          <div class="mt-5">
            <RadioButton name="user" value="email" v-model="select" />
            <label for="email">{{ newEmail }}</label>
          </div>
          <div class="mt-3 mb-4">
            <RadioButton name="user" value="phone" v-model="select" />
            <label for="phone">{{ newPhone }}</label>
          </div>
          <div class="link-profile mb-4">
            <Button class="p-button-text" @click="noAccess()"
              >Não tem mais acesso?</Button
            >
          </div>
        </div>
        <div class="mt-6">
          <Button class="button-confirm" label="Continuar" @click="recoverPassword" />
        </div>
        <div class="link my-5">
          <a href="/account/login">Cancelar</a>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="account-form info-form">
    <div class="form infos fadeinleft animation-duration-300">
      <div class="card-title">
        <h5 class="my-0 title-color">Localizar conta</h5>
      </div>
      <div class="card-body">
        <div class="recover-text mt-3 mb-3">
          <p>Insira seu e-mail ou telefone para procurar sua conta</p>
        </div>
        <div class="radio-buttons">
          <RadioButton name="user" value="phoneNumber" v-model="user" />
          <label for="phone" class="color-gray__3 mr-5">Telefone</label>
          <RadioButton name="user" value="email" v-model="user" />
          <label for="email" class="color-gray__3">E-mail</label>
        </div>
        <div v-if="user == 'phoneNumber'">
          <!-- <div>
                    <Dropdown v-model="selectedCountry" :options="countryList" class="w-full mb-0 std-input-up" optionLabel="name" placeholder="Selecione o país" />
                  </div>
                  <div>
                    <InputMask class="std-input-down" id="password-data" mask="(99)99999-9999" placeholder="(XX) X XXXX-XXXX" v-model="phone" @blur="verifyPhone" />
                  </div> -->
          <Dropdown
            class="std-top"
            v-model="selectedCountry"
            :options="countryList"
            optionLabel="name"
            placeholder="Selecione o país"
          />
          <!-- <small v-if="error['countryCode']" class="p-invalid p-mt-2">
                    <i class="pi pi-times-circle p-ml-1" />{{ error["countryCode"][0] }}
                  </small> -->
          <InputMask
            v-if="selectedCountry.code == '+55'"
            class="std-bottom btop-1"
            v-model="phone"
            mask="(99) 9 9999-9999"
            placeholder="(XX) X XXXX-XXXX"
            @keyup="validatePhone"
            maxlength="11"
          />
          <InputMask
            v-if="selectedCountry.code == '+1'"
            class="std-bottom"
            v-model="phone"
            mask="(999) 99999-9999"
            placeholder="(xxx) xxxxx-xxxx"
            :class="phoneClass"
            maxlength="12"
          />
          <small v-if="error['user']" class="p-invalid p-mt-2"
            ><i class="pi pi-times-circle p-ml-1" />{{ error["user"][0] }}</small
          >
        </div>
        <div v-else>
          <!-- <InputText class="std-input" type="text" v-model="email" placeholder="Informe seu e-mail" @blur="verifyEmail" maxlength="128"/> -->
          <InputText
            class="std-normal"
            type="text"
            v-model="email"
            placeholder="E-mail"
            @keyup="validateEmail"
            maxlength="50"
          />
          <small v-if="error['user']" class="p-invalid p-mt-2">
            <i class="pi pi-times-circle p-ml-1" />{{ error["user"][0] }}
          </small>
        </div>
        <div class="mt-6">
          <Button class="button-confirm" label="Continuar" @click="findAccount" />
        </div>
        <div v-if="hasEmail">
          <a href="/account/recover/searchAccount">Localizar conta com outros dados</a>
        </div>
        <div class="link my-5">
          <a href="/account/login">Cancelar</a>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    :closable="false"
    v-model:visible="noAccessDialog"
    :style="{ width: '450px', borderRadius: '8px' }"
    :modal="true"
  >
    <template #header>
      <h5 class="my-0 title-color">Não é possível acessar a conta</h5>
    </template>
    <div class="confirmation-content">
      <div class="text-center my-5">
        <p class="fs-5">
          Lamentavelmente, não podemos dar acesso à conta da plataforma Ommed na qual você
          está tentando entrar. Isso ocorre porque não podemos confirmar se ela pertence a
          você.
        </p>
      </div>
      <div class="button-footer d-flex fd-column jc-center ai-center">
        <div class="link text-bold fs-5 mb-6">
          <a href="/account/login">Ajuda e suporte</a>
        </div>
        <Button
          label="Concluir"
          @click="goLogin"
          :style="{
            width: '300px',
          }"
        />
      </div>
    </div>
  </Dialog>
</template>

<script>
import { ref } from "vue";
import router from "../../../router";
import {
  FindUserAccountRequest,
  SendPasswordRecoveryCodeToUserRequest,
} from "../../core/grpc/generated/professionalAccount_pb";
import professionalAcountService from "../../core/services/professionalAccountService";

export default {
  setup() {
    let user = ref('phoneNumber');
    let email = ref('');
    let newEmail = ref('');
    let phone = ref('');
    let newPhone = ref('');
    let errorPhone = ref();
    let error = ref([]);
    let hasEmail = ref(false);
    let errorEmail = ref();
    let localize = ref(false);
    let data = ref();
    let select = ref("email");
    let noAccessDialog = ref(false);

    let selectedCountry = ref({ name: "Brasil", code: "+55" });
    const countryList = [
      { name: "Brasil", code: "+55" },
      // { name: "Estados Unidos", code: "+1" },
    ];

    const findAccount = async () => {
      const request = new FindUserAccountRequest();
      if (user.value == "email") {
        request.setUser(email.value);
      } else if (user.value == "phoneNumber") {
        const phoneNumber = selectedCountry.value.code + phone.value.replace(/\D+/g, "");
        request.setUser(phoneNumber);
      }
      const response = await professionalAcountService.findUserAccount(request);
      if (response.success == true) {
        data.value = JSON.parse(response.data);
        localStorage.setItem('userId', data.value.id);
        if(user.value == "email") {
          newPhone.value = '+55' + phoneMask(data.value.phone);
          newEmail.value = data.value.email;
        } else {
          newEmail.value = emailMask(data.value.email);
          newPhone.value = data.value.phone;
        }
        localize.value = true;
      } else {
        error.value = JSON.parse(response.data);
        console.log(error.value);
      }
    };

    const validatePhone = () => {
      let DDD = [ 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35, 37, 38, 
      41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 63, 65, 66, 67, 68, 69, 71, 73, 
      74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88, 89, 91, 92, 93, 94, 95, 96, 97, 98, 99,
      ];
      errorPhone.value =
        DDD.indexOf(parseInt(phone.value.substring(0, 2))) == -1
          ? "Insira um DDD válido."
          : "";
    };

    const validateEmail = () => {
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      errorEmail.value = !re.test(email.value) ? "Insira um e-mail válido." : "";
    };

    const recoverPassword = async () => {
      const request = new SendPasswordRecoveryCodeToUserRequest();
      if(select.value == "email") {
        request.setUser(data.value.email)
        localStorage.setItem('user', newEmail.value);
        localStorage.setItem('email', true)
      } else if(select.value == "phone") {
        request.setUser(data.value.phone)
        localStorage.setItem('user', newPhone.value);
        localStorage.setItem('phone', true)
      }
      const response = await professionalAcountService.sendPasswordRecoveryCodeToUser(
        request
      );
      if (response.success == true) {
        router.push("/account/recover/change_password");
      } else {
        error.value = JSON.parse(response.data);
        // Object.keys(error).forEach((item) => {
        //   toast.add({severity: 'error', summary: item, detail: error[item][0], life: 3000});
        // })
      }
    };

    const noAccess = async () => {
      noAccessDialog.value = true;
    };

    const goLogin = () => {
      router.push("/account/login");
    };

    const emailMask = (email) => {
      var maskedEmail = email.replace(/([^@\.])/g, "*").split("");
      console.log(maskedEmail);
      var previous = "";
      let i = 0;
      for (i = 0; i < maskedEmail.length; i++) {
        if (i <= 1 || (i >= 1 && previous)) {
          maskedEmail[i] = email[i];
        }
        if (email[i] == "@") {
          previous = email[i];
        }
      }
      return maskedEmail.join("");
    };

    const phoneMask = (value) => {
      value = value.replace("+55", "");
      value = value.replace(/^(\d{2})(\d)/g, "($1)$2"); //Coloca parênteses em volta dos dois primeiros dígitos
      value = value.replace(/(\d{5})(\d{4})$/, "*****-$2"); //Coloca hífen entre o nono e o décimo dígitos
      value = value.substr(0, 16);
      var maskedPhone = value.replace(/(\d)/g, "*").split("");
      var previous = "";
      let i = 0;
      for (i = 0; i < maskedPhone.length; i++) {
        if (i <= 1 || (i >= 1 && previous)) {
          maskedPhone[i] = value[i];
        }
        if (value[i] == "(" || value[i] == ")") {
          previous = value[i];
        }
      }
      return maskedPhone.join("");
      // return value;
    };

    return {
      user,
      email,
      newEmail,
      phone,
      newPhone,
      recoverPassword,
      findAccount,
      data,
      select,
      hasEmail,
      selectedCountry,
      countryList,
      localize,
      validatePhone,
      validateEmail,
      errorPhone,
      errorEmail,
      error,
      noAccessDialog,
      noAccess,
      goLogin,
    };
  },
};
</script>

<style lang="scss">
@import "../../app/css/Responsive.scss";
.account-form {
  min-height: 80vh;
  .std-input {
    border-radius: 8px !important;
  }

  .form {
    .body {
      margin: 20px;
    }
    @include responsive(650px, major) {
      width: 95vw !important;
    }
  }
}

hr {
  position: relative;
  width: 460px;
  @include responsive(650px, major) {
    width: 90vw !important;
  }
}

.profile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 15px;
}

.image-profile {
  margin-right: 15px;
}

.link-profile {
  font-size: 15px;
  font-weight: 700;
}

.link {
  margin: 15px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.p-radiobutton {
  .p-radiobutton-box {
    .p-radiobutton-icon {
      background-color: #fff !important;
    }
  }
}

.recover-form {
  display: flex;
  justify-content: center;
  align-items: center;

  .recover {
    .header {
      padding: 10px;
    }
  }
}

.recover-text {
  margin: 15px auto;
  text-align: center;
  display: block;
  color: #828282;
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}

.std-input-up {
  height: 60px;
  background: #fff;
  border-radius: 8px 8px 0 0;
  align-items: center;
}

.std-input-down {
  height: 60px;
  background: #fff;
  border-radius: 0 0 8px 8px;
  align-items: center;
}

label {
  margin-right: 10px;
  margin-left: 5px;
}

.radio-buttons {
  margin: 15px auto;
}

.button-confirm {
  display: flex;
  margin: 30px auto;
  width: 318px !important;
  border-radius: 8px;
}
</style>
